var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[_c('form',{attrs:{"autocomplete":"off"}},[_c('div',{staticClass:"row"},[_c('CustomFileInput',{attrs:{"className":'col-12',"defaultImg":_vm.fullPathFileFromServer(
            _vm.complaintSector.complaintSectorImagePath,
            _vm.complaintSector.defaultImg
          ),"deleteFileStatus":_vm.deleteFileStatus &&
          !_vm.complaintSector.complaintSectorImageIsDefault &&
          _vm.checkPrivilege(_vm.hasComplaintSectorDeleteImage())},on:{"changeValue":function($event){_vm.complaintSector.mediaFile = $event.file},"deleteFile":function($event){return _vm.$emit('deleteFile')}}})],1),_c('div',{staticClass:"my-card"},[_c('div',{staticClass:"row"},[_c('CustomSelectBoxMultiple',{attrs:{"className":'col-md-6',"id":(_vm.id + "-complaintScopeTypeTokens"),"errors":_vm.errors_complaintScopeTypeTokens,"value":_vm.complaintSector.complaintScopeTypeTokens,"options":_vm.complaintScopeTypeTokensOptions,"title":_vm.$t('ComplaintTypes.complaintScopeType'),"imgName":'complaintTypes.svg',"returnArrayOfObjects":false},on:{"changeValue":function($event){_vm.complaintSector.complaintScopeTypeTokens = $event;
            _vm.$v.complaintSector.complaintScopeTypeTokens.$touch();}}}),_c('CustomInput',{attrs:{"className":'col-md-6',"id":(_vm.id + "-fullCode"),"value":_vm.complaintSector.fullCode,"title":_vm.$t('ComplaintSectors.code'),"imgName":'code.svg'},on:{"changeValue":function($event){_vm.complaintSector.fullCode = $event}}}),_c('CustomInput',{attrs:{"className":'col-md-6',"id":(_vm.id + "-complaintSectorNameAr"),"errors":_vm.errors_complaintSectorNameAr,"value":_vm.complaintSector.complaintSectorNameAr,"title":_vm.$t('ComplaintSectors.nameAr'),"imgName":'complaintSectors.svg'},on:{"changeValue":function($event){_vm.complaintSector.complaintSectorNameAr = $event;
            _vm.$v.complaintSector.complaintSectorNameAr.$touch();}}}),_c('CustomInput',{attrs:{"className":'col-md-6',"id":(_vm.id + "-complaintSectorNameEn"),"errors":_vm.errors_complaintSectorNameEn,"value":_vm.complaintSector.complaintSectorNameEn,"title":_vm.$t('ComplaintSectors.nameEn'),"imgName":'complaintSectors.svg'},on:{"changeValue":function($event){_vm.complaintSector.complaintSectorNameEn = $event;
            _vm.$v.complaintSector.complaintSectorNameEn.$touch();}}}),_c('CustomInput',{attrs:{"className":'col-md-6',"id":(_vm.id + "-complaintSectorNameUnd"),"value":_vm.complaintSector.complaintSectorNameUnd,"title":_vm.$t('ComplaintSectors.nameUnd'),"imgName":'complaintSectors.svg'},on:{"changeValue":function($event){_vm.complaintSector.complaintSectorNameUnd = $event}}}),_c('TextArea',{attrs:{"className":'col-md-6',"id":(_vm.id + "-complaintSectorDescriptionAr"),"errors":_vm.errors_complaintSectorDescriptionAr,"value":_vm.complaintSector.complaintSectorDescriptionAr,"title":_vm.$t('ComplaintSectors.descriptionAr'),"imgName":'description.svg'},on:{"changeValue":function($event){_vm.complaintSector.complaintSectorDescriptionAr = $event;
            _vm.$v.complaintSector.complaintSectorDescriptionAr.$touch();}}}),_vm._v(" "),_c('TextArea',{attrs:{"className":'col-md-6',"id":(_vm.id + "-complaintSectorDescriptionEn"),"errors":_vm.errors_complaintSectorDescriptionEn,"value":_vm.complaintSector.complaintSectorDescriptionEn,"title":_vm.$t('ComplaintSectors.descriptionEn'),"imgName":'description.svg'},on:{"changeValue":function($event){_vm.complaintSector.complaintSectorDescriptionEn = $event;
            _vm.$v.complaintSector.complaintSectorDescriptionEn.$touch();}}}),_vm._v(" "),_c('TextArea',{attrs:{"className":'col-md-6',"id":(_vm.id + "-complaintSectorDescriptionUnd"),"value":_vm.complaintSector.complaintSectorDescriptionUnd,"title":_vm.$t('ComplaintSectors.descriptionUnd'),"imgName":'description.svg'},on:{"changeValue":function($event){_vm.complaintSector.complaintSectorDescriptionUnd = $event}}}),_vm._v(" "),_c('TextArea',{attrs:{"className":'col-md-12',"id":(_vm.id + "-complaintSectorNotes"),"value":_vm.complaintSector.complaintSectorNotes,"title":_vm.$t('notes'),"imgName":'notes.svg'},on:{"changeValue":function($event){_vm.complaintSector.complaintSectorNotes = $event}}})],1)]),_c('div',{staticClass:"form-actions"},[_c('div',{staticClass:"icon-submit",on:{"click":function($event){$event.preventDefault();return _vm.submitForm.apply(null, arguments)}}},[_c('img',{attrs:{"src":require("@/assets/images/check-icon.svg"),"title":_vm.submitName}})]),_c('div',{directives:[{name:"b-modal",rawName:"v-b-modal",value:(("ConfirmCloseSheet-" + _vm.bottomSheetName)),expression:"`ConfirmCloseSheet-${bottomSheetName}`"}],staticClass:"icon-cancel",on:{"click":function($event){$event.preventDefault();}}},[_c('img',{attrs:{"src":require("@/assets/images/cancel-icon.svg"),"title":_vm.$t('cancel')}})])])])])}
var staticRenderFns = []

export { render, staticRenderFns }